<template>
  <KPaginatedAutocomplete
      v-bind="$attrs"
      v-on="$listeners"
      :paginator="autocomplete"/>
</template>

<script>
import KPaginatedAutocomplete from '@/components/crud/fields/KPaginatedAutocomplete.vue';
import { autocomplete } from '@/api/endpoints/connector.js';

export default {
  name: 'ConnectorAutocomplete',
  components: { KPaginatedAutocomplete },
  methods: {
    autocomplete,
  },
};
</script>

<style scoped>

</style>
