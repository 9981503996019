<template>
  <div v-if="content">
    <v-btn small text @click.stop="handleClick">{{ $t('logs.actions.goToSource') }}</v-btn>
  </div>
</template>

<script>
export default {
  name: 'GoToSourceButton',
  props: {
    content: {
      type: String,
    }
  },
  methods: {
    handleClick() {
      window.open(this.content, '_blank')
    }
  }
};
</script>
