import { getPaginated } from '@/api/implementation/app/wrapper.js';

export const index = (page, perPage, search, sortBy, descending, params) => {
  params = {
    ...!!params.clientId && { clientId: params.clientId },
    ...!!params.systemId && { systemId: params.systemId },
    ...!!params.connectorId && { connectorId: params.connectorId },
    ...!!params.logTypeId && { logTypeId: Array.isArray(params.logTypeId) ? params.logTypeId : [params.logTypeId] },
    ...!!params.from && { from: params.from },
    ...!!params.to && { to: params.to },
  };
  return getPaginated('log', page, perPage, search, sortBy, descending, params)
};
