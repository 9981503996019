<template>
  <PageLayout>
    <template #header>
      <h1 class="text-h1 mb-3">
        <slot name="header"/>
      </h1>
    </template>
    <template #flow-actions>
      <slot name="flow-actions"/>
    </template>
    <template #default>
      <slot :attrs="{value: isFilterBarOpen, filters}"
            :on="{ 'update:filters': handleFiltersUpdate, input: handleFilterInput }"
            name="filters"/>

      <v-row align="center" no-gutters>
        <v-col v-if="withSearch || withFilters" class="searchbar">
          <v-row align="center" no-gutters>
            <v-expand-x-transition v-if="withSearch">
              <v-form v-if="isSearchbarOpen" @submit.prevent="performSearch">
                <VTextField v-model="searchQuery"
                            autofocus
                            class="mr-3"
                            dense
                            filled
                            hide-details
                            placeholder="Zoeken"/>
              </v-form>
            </v-expand-x-transition>
            <v-btn-toggle>
              <v-btn v-if="withSearch" height="35" icon width="60" @click="toggleSearchbar">
                <v-icon color="grey darken-1">$search</v-icon>
              </v-btn>
              <v-btn v-if="withFilters" height="35" icon width="60" @click="toggleFilterBar">
                <v-badge :value="hasFilters" color="info" dot>
                  <v-icon color="grey darken-1">$filter</v-icon>
                </v-badge>
              </v-btn>
            </v-btn-toggle>
          </v-row>
        </v-col>
      </v-row>

      <v-card class="mt-3">
        <v-card-text class="pa-0">
          <v-window v-model="view">
            <v-window-item v-for="slot in viewSlots" :key="slot">
              <slot :name="slot"/>
            </v-window-item>
          </v-window>
        </v-card-text>
      </v-card>
    </template>
  </PageLayout>
</template>

<script>
import PageLayout from '@/components/layout/PageLayout.vue';
import { mapGetters, mapMutations } from 'vuex';
import { isEmpty } from '@/application/util/isEmpty.js';

export default {
  name: 'KCrudLayout',
  components: {
    PageLayout,
  },
  data: () => ({
    view: 0,
    isSearchbarOpen: false,
    searchQuery: '',
    isFilterBarOpen: false,
  }),
  props: {
    search: {
      type: String,
      default: '',
    },
    filters: {
      type: Object,
      default: () => {
      },
    },
    withSearch: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    viewSlots() {
      return Object.keys(this.$slots).filter((slot) => slot.substr(0, 5) === 'view.');
    },
    withFilters() {
      return this.$scopedSlots.filters;
    },
    hasFilters() {
      return Object.values(this.filters).some(val => !this.isEmpty(val));
    },
  },
  created() {
    this.view = this.getSelectedCrudLayoutView()(this.$route.name);
  },
  methods: {
    ...mapMutations('settings', ['setCrudLayoutView']),
    ...mapGetters('settings', ['getSelectedCrudLayoutView']),
    performSearch() {
      this.isSearchbarOpen = false;
      this.$emit('update:search', this.searchQuery);
    },
    openSearchbar() {
      this.isSearchbarOpen = true;
    },
    toggleSearchbar() {
      this.isSearchbarOpen ? this.performSearch() : this.openSearchbar();
    },
    toggleFilterBar(to = null) {
      this.isFilterBarOpen = to === null ? !this.isFilterBarOpen : to;
    },
    handleFiltersUpdate(event) {
      return this.$emit('update:filters', event);
    },
    handleFilterInput(event) {
      return this.toggleFilterBar(event);
    },
    isEmpty,
  },
};
</script>

<style>
.searchbar {
  width: 350px;
}
</style>
