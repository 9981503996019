<template>
  <v-icon :color="logTypeColor">
    {{ logTypeIcon }}
  </v-icon>
</template>

<script>
import { LogType } from '@/application/enums/LogType.js';
export default {
  name: 'LogStatus',
  props: {
    content: {
      type: Number,
      required: true,
    },
  },
  computed: {
    logTypeIcon() {
      if (this.content === LogType.ERROR) return '$error';
      if (this.content === LogType.WARNING) return '$warning';
      if (this.content === LogType.LOG) return '$info';
      return '$info';
    },
    logTypeColor() {
      if (this.content === LogType.ERROR) return 'error';
      if (this.content === LogType.WARNING) return 'warning';
      if (this.content === LogType.LOG) return 'gray';
      return 'gray lighten-2';
    },
  },
};
</script>
