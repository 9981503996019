<template>
  <v-select :label="$t('logs.fields.logTypeName')"
            :return-object="false"
            v-bind="$attrs"
            v-on="$listeners"
            :items="logTypes"
            multiple>
    <template #item="{item}">
      <v-list-item-avatar>
        <LogStatus :content="item.value"/>
      </v-list-item-avatar>
      <v-list-item>
        {{ item.text }}
      </v-list-item>
    </template>
    <template #selection="{item}">
      <v-col cols="2">
        <LogStatus :content="item.value"/>
      </v-col>
      <v-col cols="10">{{ item.text }}</v-col>
    </template>
  </v-select>
</template>

<script>
import { LogType } from '@/application/enums/LogType.js';
import LogStatus from '@/components/logs/LogStatus.vue';

export default {
  name: 'LogTypeSelect',
  components: {
    LogStatus,
  },
  computed: {
    logTypes() {
      return Object.keys(LogType).map(key => {
        return {
          text: this.$t(`logs.logTypes.${key}`),
          value: LogType[key],
        };
      });
    }
  }
};
</script>
