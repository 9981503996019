<template>
  <KCrudLayout :filters.sync="filters" :search.sync="searchQuery">
    <template #header>{{ $tc('logs.title', 2) }}</template>
    <template #filters="{attrs, on}">
      <LogFilter v-bind="attrs" v-on="on"/>
    </template>
    <template #view.list>
      <resource ref="resource"
                :meta="{name: $tc('logs.title', 2), namePlural: $tc('logs.title', 2)}"
                :table-content="crudHeaders"
                :index-request="indexRequest"
                :search="searchQuery"
                :expanded.sync="expanded"
                :can-search="false"
                :show-expand="false"
                :parameters="filters"
                :customSearchQuery="searchQuery"
                @row-click="handleExpanding">
        <template #item.message="{item}">
          <div class="log-message" v-show="!isExpanded(item.id)">{{ item.message }}</div>
        </template>
        <template #item.link="{item}">
          <div v-if="item.link">
            <a :href="item.link || 'https://google.com'" target="_blank" v-on:click.stop>
              <span class="text-decoration-underline">{{ $t('logs.fields.link') }}</span>
              <v-icon size="20" class="pl-2">fa-external-link-alt</v-icon>
            </a>
          </div>
        </template>
        <template #expanded-item="{item}">
          <td></td>
          <td :colspan="crudHeaders.length">
            <k-display-group language-prefix="logs.fields">
              <v-row class="ma-0">
                <v-col cols="12" sm="6">
                  <k-text-display field="message">
                    {{ item.message }}
                  </k-text-display>
                </v-col>
                <v-col cols="12" sm="6">
                  <k-text-display field="clientName" v-if="item.clientName" class="mb-1">
                    {{ item.clientName }}
                  </k-text-display>
                  <k-text-display field="connectorName" v-if="item.connectorName" class="mb-1">
                    {{ item.connectorName }}
                  </k-text-display>
                  <k-text-display field="messageStatus" v-if="item.messageStatus" class="mb-1">
                    {{ item.messageStatus }}
                  </k-text-display>
                  <k-text-display field="entityTypeName" v-if="item.entityTypeName" class="mb-1">
                    {{ item.entityTypeName }}
                  </k-text-display>
                  <k-text-display field="monitorRuleName" v-if="item.monitorRuleName" class="mb-1">
                    {{ item.monitorRuleName }}
                  </k-text-display>
                  <k-text-display field="reportingSystemName" v-if="item.reportingSystemName">
                    {{ item.reportingSystemName }}
                  </k-text-display>
                </v-col>
              </v-row>
            </k-display-group>
          </td>
        </template>
      </resource>
    </template>
  </KCrudLayout>
</template>

<script lang="js">
import eventBus from '@/eventBus.js';
import { index } from '@/api/endpoints/logs.js';
import LogFilter from '@/components/logs/LogFilter.vue';
import KCrudLayout from '@/components/layout/KCrudLayout.vue';
import LogStatus from '@/components/logs/LogStatus.vue';
import GoToSourceButton from '@/components/logs/GoToSourceButton.vue';
import KTextDisplay from '@/components/crud/display/KTextDisplay.vue';
import KDisplayGroup from '@/components/crud/display/KDisplayGroup.vue';
import Resource from '@/components/Resource.vue';
import dayjs from '@/plugins/dayJs.js';
import querySyncMixin from '@/application/mixins/querySyncMixin.js';
import { LogType } from '@/application/enums/LogType.js';

export default {
  name: 'LogsResource',
  mixins: [querySyncMixin],
  components: {
    Resource,
    KDisplayGroup,
    KTextDisplay,
    KCrudLayout,
    LogFilter,
  },
  data() {
    return {
      searchQuery: '',
      expanded: [],
      filters: {
        logTypeId: [LogType.ERROR, LogType.WARNING],
        from: dayjs().format('YYYY-MM-DD'),
        to: dayjs().format('YYYY-MM-DD'),
      },
    };
  },
  computed: {
    indexRequest: () => index,
    crudHeaders() {
      return [
        {
          value: 'logTypeId',
          text: '',
          align: 'left',
          sortable: true,
          columnType: LogStatus,
          width: 30,
        },
        {
          value: 'loggedAt',
          text: this.$t('logs.crudHeaders.loggedAt'),
          sortable: true,
        },
        {
          value: 'message',
          text: this.$t('logs.crudHeaders.message'),
          sortable: false,
        },
        {
          value: 'systemName',
          text: this.$t('logs.crudHeaders.systemName'),
          sortable: true,
        },
        {
          value: 'link',
          columnType: GoToSourceButton,
          sortable: false,
        },
      ];
    },
  },
  created() {
    eventBus.$emit('setBreadcrumbs', [
      {
        exact: true,
        to: { name: 'home' },
        text: this.$t('global.dashboard'),
      },
      {
        exact: true,
        to: { name: 'logs.index' },
        text: this.$tc('logs.title', 2),
      },
    ]);
  },
  methods: {
    handleExpanding(id) {
      if (this.isExpanded(id)) {
        this.expanded = this.expanded.filter(log => log.id !== id);
      } else {
        this.expanded.push({ id });
      }
    },
    isExpanded(itemId) {
      return this.expanded.map(({ id }) => id).includes(itemId);
    },
  },
};
</script>

<style scoped>
.log-message {
  width:         400px;
  white-space:   nowrap;
  overflow:      hidden;
  text-overflow: ellipsis;

}
</style>
