var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('KCrudLayout',{attrs:{"filters":_vm.filters,"search":_vm.searchQuery},on:{"update:filters":function($event){_vm.filters=$event},"update:search":function($event){_vm.searchQuery=$event}},scopedSlots:_vm._u([{key:"header",fn:function(){return [_vm._v(_vm._s(_vm.$tc('logs.title', 2)))]},proxy:true},{key:"filters",fn:function(ref){
var attrs = ref.attrs;
var on = ref.on;
return [_c('LogFilter',_vm._g(_vm._b({},'LogFilter',attrs,false),on))]}},{key:"view.list",fn:function(){return [_c('resource',{ref:"resource",attrs:{"meta":{name: _vm.$tc('logs.title', 2), namePlural: _vm.$tc('logs.title', 2)},"table-content":_vm.crudHeaders,"index-request":_vm.indexRequest,"search":_vm.searchQuery,"expanded":_vm.expanded,"can-search":false,"show-expand":false,"parameters":_vm.filters,"customSearchQuery":_vm.searchQuery},on:{"update:expanded":function($event){_vm.expanded=$event},"row-click":_vm.handleExpanding},scopedSlots:_vm._u([{key:"item.message",fn:function(ref){
var item = ref.item;
return [_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isExpanded(item.id)),expression:"!isExpanded(item.id)"}],staticClass:"log-message"},[_vm._v(_vm._s(item.message))])]}},{key:"item.link",fn:function(ref){
var item = ref.item;
return [(item.link)?_c('div',[_c('a',{attrs:{"href":item.link || 'https://google.com',"target":"_blank"},on:{"click":function($event){$event.stopPropagation();}}},[_c('span',{staticClass:"text-decoration-underline"},[_vm._v(_vm._s(_vm.$t('logs.fields.link')))]),_c('v-icon',{staticClass:"pl-2",attrs:{"size":"20"}},[_vm._v("fa-external-link-alt")])],1)]):_vm._e()]}},{key:"expanded-item",fn:function(ref){
var item = ref.item;
return [_c('td'),_c('td',{attrs:{"colspan":_vm.crudHeaders.length}},[_c('k-display-group',{attrs:{"language-prefix":"logs.fields"}},[_c('v-row',{staticClass:"ma-0"},[_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('k-text-display',{attrs:{"field":"message"}},[_vm._v(" "+_vm._s(item.message)+" ")])],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[(item.clientName)?_c('k-text-display',{staticClass:"mb-1",attrs:{"field":"clientName"}},[_vm._v(" "+_vm._s(item.clientName)+" ")]):_vm._e(),(item.connectorName)?_c('k-text-display',{staticClass:"mb-1",attrs:{"field":"connectorName"}},[_vm._v(" "+_vm._s(item.connectorName)+" ")]):_vm._e(),(item.messageStatus)?_c('k-text-display',{staticClass:"mb-1",attrs:{"field":"messageStatus"}},[_vm._v(" "+_vm._s(item.messageStatus)+" ")]):_vm._e(),(item.entityTypeName)?_c('k-text-display',{staticClass:"mb-1",attrs:{"field":"entityTypeName"}},[_vm._v(" "+_vm._s(item.entityTypeName)+" ")]):_vm._e(),(item.monitorRuleName)?_c('k-text-display',{staticClass:"mb-1",attrs:{"field":"monitorRuleName"}},[_vm._v(" "+_vm._s(item.monitorRuleName)+" ")]):_vm._e(),(item.reportingSystemName)?_c('k-text-display',{attrs:{"field":"reportingSystemName"}},[_vm._v(" "+_vm._s(item.reportingSystemName)+" ")]):_vm._e()],1)],1)],1)],1)]}}])})]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }