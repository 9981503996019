<template>
  <k-field-group language-prefix="logs.filterFields">
    <k-sidebar v-bind="$attrs"
               v-on="$listeners"
               :save-button-text="$t('global.applyFilter')"
               :title="$t('global.filterTitle', { module: $tc('logs.title', 2) })"
               fixed
               right
               temporary
               width="300"
               @click:confirm="handleFilterClick">
      <ClientAutocomplete v-model="form.clientId"
                          field="clientName"
                          hide-details
                          :return-object="false"
                          class="mb-2"/>
      <MicroserviceAutocomplete v-model="form.systemId"
                                field="systemName"
                                hide-details
                                :return-object="false"
                                class="mb-2"/>
      <ConnectorAutocomplete v-model="form.connectorId"
                             field="connector"
                             hide-details
                             :return-object="false"
                             class="mb-2"/>
      <LogTypeSelect v-model="form.logTypeId" class="mb-2"/>

      <KDateField v-model="form.from" field="dateFrom" grid="col-sm-12 mb-2 pb-1" hide-details/>
      <KDateField v-model="form.to" field="dateTo" grid="col-sm-12 mb-2 pb-1" hide-details/>
    </k-sidebar>
  </k-field-group>
</template>

<script>
import KFieldGroup from '@/components/crud/fields/KFieldGroup.vue';
import KSidebar from '@/components/layout/KSidebar.vue';
import ClientAutocomplete from '@/components/logs/ClientAutocomplete.vue';
import MicroserviceAutocomplete from '@/components/logs/MicroserviceAutocomplete.vue';
import KDateField from '@/components/crud/fields/KDateField.vue';
import ConnectorAutocomplete from '@/components/logs/ConnectorAutocomplete.vue';
import LogTypeSelect from '@/components/logs/LogTypeSelect.vue';

export default {
  name: 'LogFilter',
  components: {
    LogTypeSelect,
    ConnectorAutocomplete,
    KDateField,
    MicroserviceAutocomplete,
    ClientAutocomplete,
    KSidebar,
    KFieldGroup,
  },
  inheritAttrs: false,
  props: {
    filters: {
      type: Object,
    },
  },
  data() {
    return {
      form: {},
    };
  },
  watch: {
    filters: {
      handler({
        logTypeId,
        from,
        to,
        systemId,
        connectorId,
        clientId,
      }) {
        this.form = {
          logTypeId: Array.isArray(logTypeId) ? logTypeId : [logTypeId],
          from,
          to,
          systemId,
          connectorId,
          clientId,
        };
      },
      deep: true,
      immediate: true,
    },
  },
  computed: {},
  methods: {
    handleFilterClick() {
      this.$emit('update:filters', this.form);
      this.$emit('input', false);
    },
  },
};
</script>
