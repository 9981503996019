export default {
  save: 'Opslaan',
  from: 'van',
  till: 'tot',
  create: 'Nieuwe maken',
  update: 'Wijzigen',
  delete: 'Verwijderen',
  'no-data': 'Er is niets gevonden...',
  'no-results': 'Er is niets gevonden...',
  'rows-per-page-text': 'Rijen per pagina',
  'snackbar-saved': 'Het is opgeslagen!',
  'snackbar-save-error': 'Er ging iets mis, probeer het later opnieuw',
  'snackbar-get-error': 'Er ging iets mis, probeer het later opnieuwr',
  'snackbar-deleted': 'Het is verwijderd!',
  'snackbar-delete-error': 'Er ging iets mis, probeer het later opnieuw',
  'search': 'Zoeken',
  'loading': 'Laden..',
  'delete-confirmation-title': 'Weet je het zeker?',
  'delete-confirmation-content': 'Weet je zeker dat je deze item(s) wilt verwijderen?',
};
